/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
	--ion-color-primary:#3378ff;
	  --ion-color-secondary:#845adf;
	--ion-color-success:#26bf94;
	--ion-color-warning:#f5b849;
	--ion-color-info:#49b6f5;
	  --ion-color-white:#ffffff;
	  --ion-color-dark: #01041b;
	--ion-color-danger: #e6533c;
	--ion-color-primary-contrast: #ffffff;
	
  }

ion-header {
	-ms-box-shadow: 0 0 30px rgba(12,37,86,.1);
  -o-box-shadow: 0 0 30px rgba(12,37,86,.1);
  box-shadow: 0 0 30px rgba(12,37,86,.1);
}
ion-split-pane {
  --side-min-width: 250px;
  --side-max-width: 250px;
}
ion-menu {
  --ion-background-color:#001546;
  --ion-text-color:#939eb8;
}
ion-menu ion-content {
  --background:#001546;
}
ion-menu-button {
	--color: #324253;
}
ion-menu ion-item {
  font-size: 14px;
  --padding-start: 10px;
  --min-height:40px;
}
ion-menu ion-item.selected {
  --background: rgba(255,255,255,0.2);
  border-radius:5px;
  --ion-text-color: #ffffff;
}
ion-menu ion-item.selected ion-icon {
	color: #ffffff;
}
ion-menu ion-item ion-icon {
  color: #939eb8;
  margin-inline-end:15px;
}
ion-menu ion-item ion-label {
  margin: 0;
  font-weight: 400;
}

ion-menu ion-content ion-list {
  padding:10px !important;
}

ion-label {
  margin-bottom: 5px;
  display: block;
}
ion-title {
	font-size: 18px;
	text-align: left;
	color: #213446;
	font-weight: 700;
}

ion-content {
  --font-size: 14px;
  --background: #f9fbfe;
  --color: #324253;
}
.password-box {
  position: relative;
}
.input-box {
	border:1px solid #cbd5e1;
	border-radius: 5px;
	font-size: 14px;
	--padding-start:10px;
	background: #ffffff;
	--padding-top:6px;
	--padding-bottom:6px;
}

.input-box-datepicker {
	width: 100%;
	padding: 8px 10px;
	line-height: 18px;
	background: #ffffff;
}
ion-button {
	--box-shadow: none;
	text-transform: capitalize;
	font-size: 14px;
	height: 34px;
	--padding-start: 15px;
	--padding-end: 15px;
	--border-radius: 5px;
	font-weight: 400;
}
 
.data-table {
	width: 100%;
	max-width: 100%;
	background-color: transparent;
	white-space: nowrap;
	margin-bottom: 15px;
}
.data-table thead th {
	vertical-align: middle;
	background: #e4e9ef;
	color: #32312f;
	font-weight: 500;
}
.data-table tbody tr:nth-child(2n) td {
	background: #f1f5f9;
}
.data-table td, .data-table th {
	padding: .75rem;
	vertical-align: middle;
	border-top: 1px solid #efeff0;
}
.data-table tfoot th {
	vertical-align: middle;
	color: #32312f;
	font-weight: 500;
	border-bottom: 1px solid #efeff0;
}

/*Data Table Non Responsive*/
.data-table-static {
	width: 100%;
	max-width: 100%;
	background-color: transparent;
	white-space: nowrap;
	margin-bottom: 15px;
}
.data-table-static thead th {
	vertical-align: middle;
	background: #e4e9ef;
	color: #32312f;
	font-weight: 500;
}
.data-table-static tbody tr:nth-child(2n) td {
	background: #f1f5f9;
}
.data-table-static td, .data-table-static th {
	padding: .75rem;
	vertical-align: middle;
	border-top: 1px solid #efeff0;
}
.data-table-static tfoot th {
	vertical-align: middle;
	color: #32312f;
	font-weight: 500;
	border-bottom: 1px solid #efeff0;
}
.custom-table {
	border: 1px solid #e2e8f0;
	width: 100%;
	margin-bottom: 5px;
	white-space: nowrap;
}
.custom-table thead th {
	color: #2b2a3f;
	padding: 8px 10px;
	border: 1px solid #e2e8f0;
	background: #f1f5f9;
	font-weight: 500;
}
.custom-table td, .custom-table th {
	padding: 8px 10px;
	vertical-align:top;
	border-top: 1px solid #e2e8f0;
	vertical-align: top;
}
.custom-table td, .custom-table th {
	padding:8px 10px;
	vertical-align: middle;
	border-top: 1px solid #efeff0;
}

ul, ol {
	padding: 0;
	margin-top: 0;
}
ul li, ol li {
	margin-bottom: 10px;
	display: block;
}
.ngx-pagination {
  padding:0px;
  text-align: right;
  margin: 0 !important;
}
.ngx-pagination li a {
	padding: 6px 14px;
	color: #2a3650;
	background: #ffffff;
	margin-left: 5px;
	border-radius: 5px;
	font-size: 0.813rem;
	line-height: 24px;
}
.ngx-pagination .disabled {
	padding:6px 14px !important;
	color: #2a3650 !important;
	background: #ffffff !important;
	margin-left: 5px;
	border-radius: 5px;
	font-size: 0.813rem;
	line-height: 24px;
}
.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next,
.ngx-pagination .current {
display:inline-block !important; 
}

.ngx-pagination .pagination-next>a {
  background: #ffffff !important;
  color: #32312f;
}
.ngx-pagination .current {
	padding:6px 14px !important;
	background: #32312f !important;
	color: #ffffff;
	border-radius: 5px;
	margin-left: 5px;
}
.btn-delete {
	background: #e6533c;
	color: #ffffff;
	width: 28px;
	height: 28px;
	display: block;
  line-height:32px;
	border-radius: 5px;
	font-size: 18px;
}
.button-back {
	--background:transparent;
	--color:#1e293b;
} 
.modal-popup {
	--fiv-padding:0 !important;
}
.table-responsive {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.btn-submit {
	--padding-start:20px;
	--padding-end:20px;
	height: 40px;
}
.ng-select .ng-select-container {
	color: #333;
	background-color: #fff;
	border-radius: 5px;
	border: 1px solid #cbd5e1;
	min-height:36px;
	align-items: center;	
}
.button-actions ion-button {
	height: 26px;
	--padding-start: 10px;
	--padding-end: 10px;
	font-size: 12px;
}
.menu-arrow {
	font-size: 16px;
	margin: 0;
}
.submenu ion-item {
	border-radius: 5px;
	margin-top: 5px;
	--min-height: 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
	appearance: textfield !important;
  -moz-appearance: textfield !important;
}

.data-table ion-input input[disabled] {
	opacity: 1 !important;
}

.theme-green .bs-datepicker-head {
    background-color: #323f4b !important;
}

.theme-green .bs-datepicker-body table td span.selected{
    background-color: #323f4b !important;
}

ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md {
    right: 43px !important;
}

.custom-grid-search {
    width: 300px;
    float: left;
    position: relative;
}

.custom-grid-search .search_icon{
    position: absolute;
    right: 8px;
    top: 7px;
    background-color: #324253;
    border-radius: 100%;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 33px;
    color: #fff;
}
.custom-grid-search ion-searchbar .searchbar-search-icon.sc-ion-searchbar-md{
    display: none !important;
}
.searchbar-input.sc-ion-searchbar-md {
    padding-inline-start: 15px;
}

ion-button.clear-hyper {
    --background: #fff !important;
    --color: rgb(0, 140, 255) !important;
    --padding-start: 0 !important;
}